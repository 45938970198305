import { render, staticRenderFns } from "./cards-support.vue?vue&type=template&id=72497a8e&scoped=true"
import script from "./cards-support.vue?vue&type=script&lang=js"
export * from "./cards-support.vue?vue&type=script&lang=js"
import style0 from "./cards-support.vue?vue&type=style&index=0&id=72497a8e&prod&lang=postcss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "72497a8e",
  null
  
)

export default component.exports