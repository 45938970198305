<template>
    <component
        :is="`header-v${content.variant || 1}`"
        :content="content"
    />
</template>

<script>
import HeaderV2 from './header-v2.vue';
import HeaderV3 from './header-v3.vue';
import HeaderV8 from './header-v8.vue';

export default {
    name: 'SMainPageHeader',
    components: {
        HeaderV2,
        HeaderV3,
        HeaderV8,
    },
    props: {
        content: {
            type: Object,
            default: null,
        },
    },
};
</script>
