export default {
    data() {
        return {
            form: {
                globalsDataCenter: null,
                standardPhysicalServers: 0,
                standardVirtualMachines: 0,
                standardWorkstations: 0,
                standardM365Seats: 0,
                standardGoogleSeats: 0,
                standardHostingServers: 0,
                advancedPhysicalServers: 0,
                advancedVirtualMachines: 0,
                advancedWorkstations: 0,
                advancedM365Seats: 0,
                advancedGoogleSeats: 0,
                advancedHostingServers: 0,
                standardNAS: 0,
                standardVMware: 0,
                standardWebsite: 0,
                standardMobile: 0,
                edr: 0,
                xdr: 0,
                mdr: 0,
                dlp: 0,
                emailSecurity: 0,
                appSecurity: 0,
                sat: 0,
                psaUsers: 0,
                rmmEndpoints: 0,
                rmmM365Seats: 0,
                cloudStorageOption: 1,
                cloudStorageAcronisCloud: 0,
                cloudStorageGoogleAzure: 0,
                cloudStorageThirdParty: 0,
                cloudStorageLocal: 0,
                cloudMsStorage: 0,
                disasterRecoveryStorage: 0,
            },
            currencyFormats: {
                USD: 'US$12,345,678.91',
                GBP: '£12,345,678.91',
                EUR: '€12,345,678.91',
                AUD: 'AU$12,345,678.91',
                CAD: 'CA$12,345,678.91',
                JPY: '¥12,345,678.91',
            },
            pricingMethodChanged: false,
            allOutputs: {},
            compare: {},
        };
    },
    computed: {
        pricingMethodReferences() {
            return this.cart.pricingMethods.items;
        },
        currentModel() {
            const currentMethod = this.pricingMethodReferences.find((el) => el.id === this.pricingMethod);
            return currentMethod.reference;
        },
        selectedPricingMethod() {
            return this.cart.pricingMethods.items.find((el) => el.id === this.pricingMethod);
        },
        monthlyTotal() {
            const total = this.allOutputs.total;
            const priceFormatted = this.formatPrice(total);
            const suffix = this.cart.labels.monthly;

            return {
                total,
                formatted: `${priceFormatted} ${suffix}`,
            };
        },
        recommendedPricingMethod() {
            if (!this.isStorage) return 1;

            return this.compare?.lowest || 1;
        },
        pricingState() {
            return this.selectedPricingMethod.id === this.recommendedPricingMethod ? 'recommended' : 'warning';
        },
        isDiscount() {
            return Boolean(this.allOutputs.total > this.discountAfter);
        },
    },
    methods: {
        calculate() {
            Object.keys(this.form).forEach((key) => {
                const price = this.getPriceForKey(key, false, true);
                this.$set(this.allOutputs, key, price);
            });
            this.calculateOrderTotals();
            this.calculateTotal();
            this.dispatchEmptyResize(false);
            this.generatePricesForCompare();
        },
        getPriceForKey(key, pricingMethod = false, setFalsy = false) {
            const blacklist = ['globalsDataCenter', 'cloudStorageOption'];
            if (blacklist.includes(key) || !this.form[key]) return 0;

            const productCode = this.getProductCode(key, pricingMethod || this.currentModel);
            if (!productCode) {
                if (setFalsy) return this.$set(this.allOutputs, key, false);
                return 0;
            }

            const unit = this.$store.state.vroi.unitPrices.find((el) => el.product_code === productCode);
            const unitPrice = unit?.value;
            const qty = this.form[key];

            return unitPrice * qty;
        },
        calculateTotal() {
            const groups = ['cloud', 'backup', 'security', 'rmm', 'psa'];
            const total = groups.reduce((value, key) => value + this.allOutputs[key], 0);
            this.$set(this.allOutputs, 'total', total);
        },
        calculateOrderTotals() {
            const cloudFields = [
                ...this.cloudOrderLineFields.map((el) => el.item).filter(Boolean),
                ...this.storageOrderLineFields.map((el) => el.item).filter(Boolean),
            ];
            const backupFields = this.backupOrderLineFields.map((el) => el.item).filter(Boolean);
            const rmmFields = this.rmmOrderLineFields.map((el) => el.item).filter(Boolean);
            const securityFields = this.securityOrderLineFields.map((el) => el.item).filter(Boolean);
            const psaFields = this.psaOrderLineFields.map((el) => el.item).filter(Boolean);

            this.$set(this.allOutputs, 'cloud', this.sumArrayValues(cloudFields));
            this.$set(this.allOutputs, 'backup', this.sumArrayValues(backupFields));
            this.$set(this.allOutputs, 'security', this.sumArrayValues(securityFields));
            this.$set(this.allOutputs, 'rmm', this.sumArrayValues(rmmFields));
            this.$set(this.allOutputs, 'psa', this.sumArrayValues(psaFields));
        },
        generatePricesForCompare() {
            this.pricingMethodReferences.forEach((method) => {
                const pricingMethod = method.reference;
                this.$set(this.compare, pricingMethod, {});

                Object.keys(this.form).forEach((key) => {
                    const price = this.getPriceForKey(key, pricingMethod, false);
                    this.$set(this.compare[pricingMethod], key, price);
                });
            });

            const pricingMethods = this.pricingMethodReferences.map(({ reference, id }) => ({
                value: this.getTotalValue(this.compare[reference]),
                id,
                reference,
            }));

            const lowestPricingMethod = pricingMethods
                .reduce((minMethod, currentMethod) => (currentMethod.value < minMethod.value ? currentMethod : minMethod));
            this.$set(this.compare, 'lowest', lowestPricingMethod.id);
        },
        getTotalValue(obj) {
            if (!obj || Object.keys(obj).length === 0) return 0;
            return Object.values(obj).reduce((total, currentValue) => total + currentValue, 0);
        },
        formatPrice(price = 0) {
            const format = this.currencyFormats[this.currency];
            return this.setCurrency(price, { format, zeroByDefault: true });
        },
        sumKeyValues(keysToSum) {
            const result = keysToSum.reduce((sum, key) => {
                const value = this.form[key];
                return sum + (typeof value === 'number' ? value : 0);
            }, 0);

            return result;
        },
        sumArrayValues(valuesToSum) {
            return valuesToSum.reduce((sum, value) => sum + value, 0);
        },
        fetchProductCodesForPriceBook() {
            const blacklist = ['globalsDataCenter', 'cloudStorageOption'];
            const productCodes = Object.keys(this.form).flatMap((key) => {
                if (blacklist.includes(key)) return [];
                return this.pricingMethodReferences.map((method) => {
                    const productCode = this.getProductCode(key, method.reference);
                    return productCode || null;
                }).filter(Boolean);
            });
            return productCodes;
        },
        getProductCode(reference, pricingMethodReference) {
            const unit = this.productCodes[reference];
            if (!unit) return false;

            if (unit.storageDependant) {
                const options = unit[this.form.cloudStorageOption];
                if (options[pricingMethodReference]?.dcDependant) return options[pricingMethodReference][this.group];
                return options[pricingMethodReference];
            }

            if (unit[pricingMethodReference]?.dcDependant) return unit[pricingMethodReference][this.group];
            return unit[pricingMethodReference];
        },
        changePricingMethod(value, manual = false) {
            if (this.pricingMethod === value) return;
            if (manual) { this.pricingMethodChanged = true; }
            this.$store.commit('vroi/setPricingMethod', value);
            this.calculate();
        },
        showOrderGroup(group) {
            if (group === 'cloud') {
                const selectedAny = this.sumKeyValues(this.cloudOrderLineFields.map((el) => el.reference));
                return this.allOutputs[group] || selectedAny || this.isStorage;
            }
            return this.allOutputs[group] > 0;
        },
        showOrderItem(reference) {
            const item = this.allOutputs[reference];
            const hasQty = this.form[reference] > 0;
            return hasQty || (item && item && parseFloat(item) > 0);
        },
        getOrderItemQty(reference, suffix = false) {
            let orderLine = '';
            const field = this.findField(reference);

            orderLine += this.form[reference];
            if (suffix) orderLine += ` ${suffix} `;
            orderLine += ` x ${field.label} `;

            return orderLine;
        },
    },
};
