/* eslint-disable func-names */
import { LOCALE_DEFAULT } from '@model/const/locales.ts';
import commonUtils from '@utils/common';
import formHelper from '@utils/form.js';

export default {
    data() {
        return {
            isLoading: true,
            cartRequirements: {
            },
            expandedCart: true,
            detailedSummary: true,
            pdfInProgress: false,
            pdfError: false,
        };
    },
    watch: {
        'form.globalsDataCenter': async function (val) {
            const dataCenter = this.dataCenters.find((el) => el.code === val);
            this.$store.commit('vroi/setGroup', dataCenter?.group);
            await this.init(false);
        },
        showMsStorage() {
            this.changeInput('cloudMsStorage', 0);
        },
        showDrStorage() {
            this.changeInput('disasterRecoveryStorage', 0);
        },
        requirementsMet() {
            this.dispatchEmptyResize(true, 500);
            // this.changePricingMethod(this.recommendedPricingMethod);
        },
        isDiscount() {
            this.dispatchEmptyResize(true, 500);
        },
        isActiveMDR(value) {
            if (!value) {
                this.changeInput('mdr', 0);
            }
        },
        recommendedPricingMethod(method) {
            if (this.pricingMethodChanged) return;
            this.changePricingMethod(method);
        },
        async gated(isGated) {
            if (isGated) return;
            await this.initSidebar();
            this.isLoading = false;
        },
        isMobileTablet(isMobileTablet) {
            this.expandedCart = !isMobileTablet;
            if (!isMobileTablet) commonUtils.globalOverflowProperty('auto');
            this.checkSidebar();
        },
    },
    computed: {
        locale() {
            return this.$route?.params.locale || LOCALE_DEFAULT;
        },
        showOnMobile() {
            return this.isMobileTablet && this.expandedCart;
        },
        assistanceLinkFormatted() {
            if (!this.cart.notFilled) return false;
            return this.cart.notFilled.assistance.replace('{link}', `<span>${this.cart.notFilled.linkTitle}</span>`);
        },
        bottomLinkFormatted() {
            if (!this.bottomContact) return false;
            return this.bottomContact.assistance.replace('{link}', `<span>${this.bottomContact.linkTitle}</span>`);
        },
        showMsStorage() {
            return this.form.standardM365Seats || this.form.standardGoogleSeats;
        },
        showDatacenter() {
            return this.form.cloudStorageOption === 1;
        },
        showDrStorage() {
            const storageRequired = [1, 3].includes(this.form.cloudStorageOption);
            const inputsRequired = this.form.standardPhysicalServers || this.form.standardVirtualMachines;
            return storageRequired && inputsRequired;
        },
        showGoogleStorage() {
            return this.hasGoogleSeats;
        },
        isActiveMDR() {
            return this.form.edr || this.form.xdr;
        },
        isDataProtection() {
            return this.selectedServices.includes('cloud');
        },
        isStorage() {
            const keysToSum = [
                ...this.storageOrderLineFields.map((el) => el.reference),
            ];
            return this.sumKeyValues(keysToSum);
        },
        isSelectedAny() {
            const keysToSum = [
                ...this.cloudOrderLineFields.map((el) => el.reference),
                ...this.backupOrderLineFields.map((el) => el.reference),
                ...this.securityOrderLineFields.map((el) => el.reference),
                ...this.psaOrderLineFields.map((el) => el.reference),
                ...this.rmmOrderLineFields.map((el) => el.reference),
            ];
            return this.sumKeyValues(keysToSum);
        },
        requirementsMet() {
            return this.isSelectedAny || this.isStorage;
        },
        pdfTitle() {
            return this.cart.labels?.export[this.pdfInProgress ? 'progress' : 'generate'];
        },
        detailedSummaryTitle() {
            return this.cart.labels?.view[this.detailedSummary ? 'compact' : 'detailed'];
        },
        cartBackdropVisible() {
            if (!this.isMobileTablet) return false;
            return this.requirementsMet && !this.isDiscount && this.expandedCart;
        },
    },
    methods: {
        findField(reference) {
            return this.fields.find((el) => el.reference === reference);
        },
        async changeStorageOptionValue(reference, value) {
            this.changeInput('cloudStorageAcronisCloud', 0, false);
            this.changeInput('cloudStorageGoogleAzure', 0, false);
            this.changeInput('cloudStorageThirdParty', 0, false);
            this.changeInput('cloudStorageLocal', 0, false);

            await this.$nextTick();
            this.changeInput(reference, value);
            await this.init();
        },
        downloadPdfClick() {
            formHelper.sendDataLayer({
                event: 'Acronis',
                eventCategory: 'Interactions',
                eventAction: 'documents',
                eventLabel: 'Your_Custom_Service_Provider_Pricing_from_Acronis',
                eventContent: this.selectedPricingMethod?.title,
            });
        },
        async exportPdf(generatorBaseURL) {
            if (this.pdfInProgress || this.pdfError) return;
            this.pdfInProgress = true;

            formHelper.sendDataLayer({
                event: 'Acronis',
                eventCategory: 'Interactions',
                eventAction: 'link',
                eventLabel: 'pdf export',
                eventContent: this.selectedPricingMethod?.title,
            });

            try {
                const timestamp = new Date().toISOString().slice(0, 10).replace(/-/g, '');
                const fileName = `Your_Custom_Service_Provider_Pricing_from_Acronis-${timestamp}.pdf`;

                const data = {
                    defaults: {
                        currency: this.currency,
                        pricingMethod: this.pricingMethod,
                        discountAfter: this.discountAfter,
                        monthlyTotal: this.monthlyTotal?.formatted,
                    },
                    form: { ...this.form },
                    prices: { ...this.allOutputs },
                };

                const body = {
                    templateId: 'vroi-pricing.v2',
                    format: 'pdf',
                    data,
                };

                const generatorURL = `${generatorBaseURL}/remote-render/stored-template`;

                const response = await fetch(generatorURL, {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify(body),
                });

                if (response.ok) {
                    const blob = await response.blob();
                    const url = window.URL.createObjectURL(blob);
                    const a = document.createElement('a');
                    a.style.display = 'none';
                    a.href = url;
                    a.download = fileName;
                    document.body.appendChild(a);
                    a.click();
                    window.URL.revokeObjectURL(url);
                    a.remove();
                } else {
                    this.pdfError = true;
                }
            } catch {
                this.pdfError = true;
                return;
            } finally {
                this.pdfInProgress = false;
            }
        },
        toggleDetailedSummary() {
            this.detailedSummary = !this.detailedSummary;
            this.dispatchEmptyResize(false);
        },
        toggleExpandedCart() {
            this.expandedCart = !this.expandedCart;

            if (this.expandedCart && this.cartBackdropVisible) {
                commonUtils.globalOverflowProperty('hidden');
            } else {
                commonUtils.globalOverflowProperty('auto');
            }
        },
        toggleAdditionalWorkload(reference) {
            const fieldMap = {
                nas: 'standardNAS',
                vmware: 'standardVMware',
                website: 'standardWebsite',
                mobile: 'standardMobile',
            };

            if (this.activeWorkloads.includes(reference)) {
                this.activeWorkloads = this.activeWorkloads.filter((item) => item !== reference);

                if (fieldMap[reference]) {
                    this.changeInput(fieldMap[reference], 0);
                }
            } else {
                this.activeWorkloads.push(reference);
            }
        },
        setEvent(item) {
            return item ? { category: 'Interactions', action: 'link', label: item.to } : {};
        },
        dispatchEmptyResize(debounce = true, timer = 150) {
            if (!debounce) return window.dispatchEvent(new Event('resize'));

            clearTimeout(this.resizeTimer);
            this.resizeTimer = setTimeout(() => window.dispatchEvent(new Event('resize')), timer);
            return this.resizeTimer;
        },
    },
};
