<template>
    <section v-if="isLoaded" :id="id" :class="{ ...basicClassName }">
        <s-purchasing-version-2 :purchase-page-c-t-a="purchasePageCTA" :version="version" :page="page" />
    </section>
</template>

<script>
import { mapValues } from 'lodash';
import contentMixin from '@core/mixins/content.js';
import purchasing from '@core/mixins/purchasing';
import styleMixin from '@core/mixins/style.js';
import { LOCALE_DEFAULT } from '@model/const/locales.ts';
import SPurchasingVersion2 from './version-2/index.vue';
import mixin from './version-2/mixin.js';

export default {
    name: 'SPurchasingCyberProtect',
    components: {
        SPurchasingVersion2,
    },
    mixins: [contentMixin, styleMixin, purchasing, mixin],
    props: {
        page: {
            type: Object,
            default: null,
        },
        purchasePageCTA: {
            type: Object,
            default: null,
        },
    },
    data: () => ({
        STORE_SLICE_NAME: 's-purchasing-cyber-protect',
        searchParams: null,
        isLoaded: false,
        version: 2,
    }),
    computed: {
        slice() {
            return this.$store.state.slices.items?.[this.STORE_SLICE_NAME] || {};
        },
        locale() {
            return this.$route?.params.locale || LOCALE_DEFAULT;
        },
        currentVersion() {
            // WEB-47970
            if (this.page?.version === 3 && this.slice?.['version-3']) return 3;

            // WEB-47487' - Set version 1 only for en-us and de-de
            return ['en-us', 'de-de'].includes(this.locale) ? 1 : 2;
        },
    },
    mounted() {
        this.version = this.currentVersion;
        this.isLoaded = true;
    },
    async serverPrefetch() {
        await this.$store.dispatch('slices/getSyncedData', { slice: 's-loader', locale: this.locale });
        await this.$store.dispatch('slices/getSyncedData', { slice: this.STORE_SLICE_NAME, locale: this.locale });

        const listCartID = this.slice?.specialOffer?.licenseID || {};
        const listCleverBridgeCartID = mapValues(listCartID, (item) => ({ cleverBridgeCartID: item }));
        const slice = { ...this.slice, ...listCleverBridgeCartID };
        const settings = { ...this.purchasePageCTA, isPurchase: true };

        await this.findCleverBridgeCart(slice, settings);

        const awards = this.slice?.['version-2']?.dictionary?.expert?.awards || [];
        const awardsPromises = awards.map((item) => this.$store.dispatch('awards/getAward', item));
        await Promise.all(awardsPromises);
    },
};
</script>
