import { render, staticRenderFns } from "./g2-reviews.vue?vue&type=template&id=cbec48de&scoped=true"
import script from "./g2-reviews.vue?vue&type=script&lang=ts"
export * from "./g2-reviews.vue?vue&type=script&lang=ts"
import style0 from "./g2-reviews.vue?vue&type=style&index=0&id=cbec48de&prod&lang=postcss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cbec48de",
  null
  
)

export default component.exports