import { render, staticRenderFns } from "./product-demo-modal.vue?vue&type=template&id=3bb8dbaf&scoped=true"
import script from "./product-demo-modal.vue?vue&type=script&lang=js"
export * from "./product-demo-modal.vue?vue&type=script&lang=js"
import style0 from "./product-demo-modal.vue?vue&type=style&index=0&id=3bb8dbaf&prod&lang=postcss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3bb8dbaf",
  null
  
)

export default component.exports